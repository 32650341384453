import http from '../api';
import Paginator from '../_paginator';

// Workspaces
let getAll = (config) => {
  return new Paginator({
    url: 'workspaces',
    config: config,
  });
};
let store = (data) => {
  return http.post('workspaces', data);
};
let get = async (data) => {
  const res = await http.get(`workspaces/${data.id}`);
  return res;
};

let getAllUsersWorkspaces = (config) => {
  return new Paginator({
    url: 'get-all-users-workspace',
    config: config,
  });
};

let getAllUserWorkspace = (data) => {
  return http.post('get-all-user-workspaces/', data);
};
let getUserWorkspace = (data) => {
  return http.get(`get-user-workspace/${data.id}&${data.workspace_id}`);
};
let update = (data) => {
  return http.put(`workspaces/${data.id}`, data);
};
let updateUserWorkspace = (data) => {
  return http.post(`update-user-permision-workspaces/${data.user.id}`, data);
};
let deletee = (data) => {
  return http.delete(`workspaces/${data.id}`, data);
};

// Users

let getAllWorkspaceMembers = (data) => {
  return http.get(`get-workspace-users/${data.workspace_id}/${data.inactive}`);
};

let getOneWorkspaceMembers = (data) => {
  return http.get(`get-one-workspace-user/${data.member_id}/${data.workspace_id}`);
};

let updateOneWorkspaceMembers = (data) => {
  return http.put(`update-workspace-user/${data.member_id}`, data);
};

export default {
  getAllWorkspaceMembers,
  getOneWorkspaceMembers,
  updateOneWorkspaceMembers,
  getAll,
  store,
  get,
  getAllUsersWorkspaces,
  getAllUserWorkspace,
  getUserWorkspace,
  update,
  updateUserWorkspace,
  deletee,
};
