import { expenseDao } from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {
    async getAllExpenses(_, payload) {
      return new Promise((resolve, reject) => {
        expenseDao
          .getAll(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getExpenses(_, payload) {
      return new Promise((resolve, reject) => {
        expenseDao
          .getExpenses(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getExpense(_, payload) {
      return new Promise((resolve, reject) => {
        expenseDao
          .getOne(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async updateExpense(_, payload) {
      return new Promise((resolve, reject) => {
        expenseDao
          .update(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async saveExpense(_, payload) {
      return new Promise((resolve, reject) => {
        expenseDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updateExpenseInstallment(_, payload) {
      return new Promise((resolve, reject) => {
        expenseDao
          .updateExpenseInstallment(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async destroyExpense(_, payload) {
      return new Promise((resolve, reject) => {
        expenseDao
          .destroyy(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  getters: {},
};
