import http from '../api';
import Paginator from '../_paginator';

let getAll = (config) => {
  return new Paginator({
    url: 'project-expenses',
    config: config,
  });
};
let store = (data) => {
  return http.post('expenses', data);
};
let get = (data) => {
  return http.get(`transactions/${data.id}`, data);
};

let getPastYearOfficeCost = (data) =>{
  return http.get(`past-year-office-cost?workspace_id=${data.workspace_id}&group=${data.group}&expense=${data.expense}`);
}
let update = (data) => {
  return http.put(`transactions/${data.id}`, data);
};
let activateCosts = (data) => {
  return http.put(`activate-costs/${data.id}`, data);
};

let deletee = (data) => {
  return http.delete(`transactions/${data.id}`, data);
};

let calc = (data) => {
  return http.get(
    `users-cost?workspace_id=${data.workspace_id}&month=${data.month}&year=${data.year}`,
    data
  );
};

export default {
  getAll,
  store,
  get,
  calc,
  update,
  activateCosts,
  deletee,
  getPastYearOfficeCost
};
