import { workspaceDao } from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {
    // Workspaces
    async getAllWorkspaces(_, payload) {
      const config = {
        params: {
          perPage: payload.perPage,
          inactive: payload.inactive,
          page: payload.page,
        },
      };
      return new Promise((resolve, reject) => {
        workspaceDao
          .getAll(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async saveWorkspace(_, payload) {
      return new Promise((resolve, reject) => {
        workspaceDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getWorkspace(_, payload) {
      return new Promise((resolve, reject) => {
        workspaceDao
          .get(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getAllUsersWorkspaces(_, payload) {
      const config = {
        params: {
          perPage: payload.perPage,
          inactive: payload.inactive,
          page: payload.page,
          id: payload.id
        },
      };
      return new Promise((resolve, reject) => {
        workspaceDao
          .getAllUsersWorkspaces(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getAllUserWorkspace(_, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        workspaceDao
          .getAllUserWorkspace(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updateWorkspace(_, payload) {
      return new Promise((resolve, reject) => {
        workspaceDao
          .update(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async updateUserWorkspace(_, payload) {
      return new Promise((resolve, reject) => {
        workspaceDao
          .updateUserWorkspace(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getUserWorkspace(_, payload) {
      return new Promise((resolve, reject) => {
        workspaceDao
          .getUserWorkspace(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getInactiveWorkspaces(_, payload) {
      return new Promise((resolve, reject) => {
        workspaceDao
          .getAll({ active: false, ...payload })
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // Users

    async getAllWorkspaceMembers(_, payload) {
      return new Promise((resolve, reject) => {
        workspaceDao
          .getAllWorkspaceMembers(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getOneWorkspaceMembers(_, payload) {
      return new Promise((resolve, reject) => {
        workspaceDao
          .getOneWorkspaceMembers(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async updateOneWorkspaceMembers(_, payload) {
      return new Promise((resolve, reject) => {
        workspaceDao
          .updateOneWorkspaceMembers(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {},
};
