//http://0.0.0.0:3343/v1/projects
import projectDao from '@/service/projects';

export default {
  state: {},
  mutations: {},
  actions: {
    async _getProjects(_, payload) {
      return new Promise((resolve, reject) => {
        projectDao
          .getAll(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async getProject(_, payload) {
      return new Promise((resolve, reject) => {
        projectDao
          .get(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async saveProject(_, payload) {
      return new Promise((resolve, reject) => {
        projectDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async updateProject(_, payload) {
      return new Promise((resolve, reject) => {
        projectDao
          .update(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async arquiveProject(_, payload) {
      return new Promise((resolve, reject) => {
        projectDao
          .deletee(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async unArquiveProject(_, payload) {
      return new Promise((resolve, reject) => {
        projectDao
          .unDelete(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async getProjectDashboard(_, payload) {
      return new Promise((resolve, reject) => {
        projectDao
          .dashboard(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    async getProjectDefaultStages() {
      return new Promise((resolve, reject) => {
        projectDao
          .getProjectDefaultStages()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async getProjectStages(_, payload) {
      return new Promise((resolve, reject) => {
        projectDao
          .getProjectStages(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },

  getters: {},
};
