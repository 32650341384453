import http from '../api';
// import Paginator from '../_paginator';

let getAll = async (config) => {
  const { workspace_id, archived, page, perPage } = config;

  const response = await http.get(`expenses/?workspace_id=${workspace_id}&archived=${archived}&page=${page}&perPage=${perPage}`, {
    workspace_id: config.workspace_id,
    archived: config.archived,
    page: config.page,
    perPage: config.perPage
  });
  return response;
};

let getOne = (data) => {
  return http.get(`expenses/${data.id}`);
};

let getExpenses = async (data) => {
   const response = await http.get(`expenses/?workspace_id=${data.workspace_id}&project_id=${data.project_id}&status=${data.status}&archived=${data.archived}&page=${data.page}&perPage=${data.perPage}&category=${data.category}`)
  return response
}

let store = (data) => {
  return http.post('expenses', data);
};

let update = async (data) => {
  const response = await http.put(`expenses/${data.id}`, data);
  return response;
};

let updateExpenseInstallment = async (data) => {
  const response = await http.put(`expenseInstallment/${data.id}`, data);
  return response;
};

let destroyy = async (data) => {
  const response = await http.delete(`expenses/${data}`);
  return response;
};

let activate = async (data) => {
  const response = await http.put(`expenses-reactive?id=${data.id}`);
  return response;
};

export default {
  getAll,
  getOne,
  getExpenses,
  store,
  update,
  activate,
  destroyy,
  updateExpenseInstallment
};
