import ls from 'local-storage';
import { authDao } from '@/service';

export default {
  state: {
    user: null,
    currentWorkspace: null,
    multipleWorkspaces: false,
    workspaces: [],
    currentNotFinishedConsultations: [],
    toastStatus: false,
    notFinishedConsultation: 0,
    notificationVisible: false,
  },
  mutations: {
    UNSET_USER(state) {
      state.user = null;
    },
    SET_USER(state, payload) {
      ls.set('u', payload);
      state.user = payload;
    },
    SET_MUTIPLE_WORKSPACES(state, payload) {
      state.multipleWorkspaces = payload;
    },
    SET_WORKSPACES(state, payload) {
      state.workspaces = payload;
    },
    SET_NOT_FINISHED_CONSULTATIONS(state, payload) {
      state.currentNotFinishedConsultations = payload;
    },
    SET_CURRENT_WORKSPACE(state, payload) {
      ls.set('w', payload);
      state.currentWorkspace = payload;
    },
    SET_APPEND_TOAST(state, payload) {
      state.toastStatus = payload;
    },
    SET_VISIBLE_TOAST(state, payload) {
      state.notificationVisible = payload;
    },
    SET_NFC(state, payload) {
      state.notFinishedConsultation = payload;
    },
  },
  actions: {
    async initAuth({ commit }) {
      return new Promise((resolve, reject) => {
        if (!ls.get('t')) {
          commit('UNSET_USER');
          resolve();
          return;
        } else if (ls.get('t')) {
          authDao
            .getUser()
            .then((resp) => {
              return new Promise((resolve) => {
                if (ls.get('u')) {
                  commit('SET_USER', ls.get('u'));
                } else {
                  commit('SET_USER', resp.data.user_company);
                }
                commit('SET_CURRENT_WORKSPACE', ls.get('w'));
                const filteredAccesses =
                  resp.data.user_company.accesses.filter((access) => {
                    return (
                      access.workspaces && access.workspaces.disabled === false
                    );
                  });

                commit('SET_WORKSPACES', filteredAccesses);
                resolve();
              })
                .then(() => {
                  resolve(resp);
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              commit('UNSET_USER');
              ls.clear();
              reject(err);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },

    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .login(payload)
          .then(async (resp) => {
            await ls.set('t', resp.data.token);
            authDao
              .getUser()
              .then((respUser) => {
                commit('SET_USER', respUser.data.user_company);

                if (
                  respUser.data.user_company &&
                  respUser.data.user_company.accesses.length === 1
                ) {
                  commit(
                    'SET_CURRENT_WORKSPACE',
                    respUser.data.user_company.accesses[0].workspaces
                  );
                  commit('SET_WORKSPACES', respUser.data.user_company.accesses);
                } else if (
                  respUser.data.user_company &&
                  respUser.data.user_company.accesses.length > 1
                ) {
                  const filteredAccesses =
                    respUser.data.user_company.accesses.filter((access) => {
                      return (
                        access.workspaces &&
                        access.workspaces.disabled === false
                      );
                    });

                  commit('SET_MUTIPLE_WORKSPACES', true);
                  commit('SET_WORKSPACES', filteredAccesses);
                }
                resolve(respUser);
              })
              .catch((err) => {
                commit('UNSET_USER');
                ls.clear();
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changePassword(_, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .changePassword(payload, ls.get('t'))
          .then((resp) => {
            resolve(resp);
          })
          .catch((erro) => {
            reject(erro);
          });
      });
    },
    updatePassword(_, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .updatePassword(payload)
          .then((resp) => {
            resolve(resp);
          })
          .catch((erro) => {
            reject(erro);
          });
      });
    },
    updateAuthUser(_, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .updateAuthUser(payload.user_id, payload.body)
          .then((resp) => {
            resolve(resp);
          })
          .catch((erro) => {
            reject(erro);
          });
      });
    },
    recoveryPassword(_, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .recoveryPassword(payload)
          .then((resp) => {
            resolve(resp);
          })
          .catch((erro) => {
            reject(erro);
          });
      });
    },
    verifyHashRecoveryPassword(_, payload) {
      return new Promise((resolve, reject) => {
        authDao
          .verifyHashRecoveryPassword(payload)
          .then((resp) => {
            ls.set('t', resp.data.token.token);
            resolve(resp);
          })
          .catch((erro) => {
            reject(erro);
          });
      });
    },
    logout() {
      this.commit('SET_CURRENT_WORKSPACE', null);
      ls.clear();
    },
  },
  getters: {
    user: (state) => state.user,
    workspaces: (state) => state.workspaces,
    currentNotFinishedConsultations: (state) =>
      state.currentNotFinishedConsultations,
    currentWorkspace: (state) => state.currentWorkspace,
    toastStatus: (state) => state.toastStatus,
    multipleWorkspaces: (state) => state.multipleWorkspaces,
    notificationVisible: (state) => state.notificationVisible,
    notFinishedConsultation: (state) => state.notFinishedConsultation,
  },
};
